<template>
    <svg class="c-icon" :class="'c-icon-' + id">
        <use :xlink:href="'/_prototype/dist/sprite/sprite.svg#' + id"></use>
    </svg>
</template>

<script>

    //import svgxuse from 'svgxuse';

    export default {
        props: {
            id: {
                type: String,
                required: true
            }
        }
    }
</script>

<style>

</style>

