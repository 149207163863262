var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-tabs" }, [
    _c("div", { staticClass: "c-tabs-nav" }, [
      _c(
        "ul",
        { staticClass: "o-flex o-flex-align-content-start" },
        _vm._l(_vm.tabs, function(tab) {
          return _c("li", { class: { "is-active": tab.isActive } }, [
            _c(
              "a",
              {
                staticClass: "u-padding-sm",
                attrs: { href: tab.href },
                on: {
                  click: function($event) {
                    _vm.selectTab(tab.name)
                  }
                }
              },
              [_vm._v(_vm._s(tab.name))]
            )
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-tabs-details" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }