// Vendors
import $ from "jquery";
import Vue from 'vue';
import slick from 'vue-slick';

// Components
import navmobile from './components/navmobile.vue';
import icons from './components/icons.vue';
import tabs from './components/tabs.vue';
import tab from './components/tab.vue';

// Forms
import frmbutton from './components/forms/frmbutton.vue';
import frmcheckbox from './components/forms/frmcheckbox.vue';
import frmdatepicker from './components/forms/frmdatepicker.vue';
import frminput from './components/forms/frminput.vue';
import frmradio from './components/forms/frmradio.vue';
import frmselect from './components/forms/frmselect.vue';
import frmtextarea from './components/forms/frmtextarea.vue';

class App {

    constructor() {}

    init() {

        new Vue({

            el: '#app',
            delimiters: ['<%', '%>'],
            components: {

                // Vendors
                'slick': slick,

                // Components
                'navmobile': navmobile,
                'icon': icons,
                'tabs': tabs,
                'tab': tab,

                // Form Elements
                'frmbutton': frmbutton,
                'frmcheckbox': frmcheckbox,
                'frmdatepicker': frmdatepicker,
                'frminput': frminput,
                'frmradio': frmradio,
                'frmselect': frmselect,
                'frmtextarea': frmtextarea
            },

            data() {

                return {

                    slickColorsOptions: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        lazyLoad: 'ondemand',
                        arrows: true,
                        dots: false,
                        centerMode: true,
                        focusOnSelect: false,
                        infinite: true,
                        accessibility: true,
                        responsive: [
                            {
                                breakpoint: 960,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    arrows: false,
                                }
                            }
                        ]
                    }
                }
            }
        });
    }
}

$(() => {

    const app = new App();

    app.init();

});
