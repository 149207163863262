<template>

    <div class="c-tabs">

        <div class="c-tabs-nav">
            <ul class="o-flex o-flex-align-content-start">
                <li v-for="tab in tabs" :class="{ 'is-active': tab.isActive }">
                    <a :href="tab.href" @click="selectTab(tab.name)" class="u-padding-sm">{{ tab.name }}</a>
                </li>
            </ul>
        </div>

        <div class="c-tabs-details">
            <slot></slot>
        </div>

    </div>

</template>

<script>

    export default {

        data() {

            return {tabs: [] };
        },

        created() {

            this.tabs = this.$children;
        },

        methods: {

            selectTab(selectedTab) {

                this.tabs.forEach(tab => {
                    tab.isActive = (tab.name === selectedTab);
                });
            }
        }
    }

</script>

<style>


</style>